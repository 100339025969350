import { Atom, Cmp, Ctrl, state } from ":mods";
import { AModuleSubModule, AStageModule, ASubmoduleMaterial, EditModuleViewProps } from "../../models";
import { createMaterial, deleteMaterial, getMaterialList, getSubModuleDetails, updateMaterial } from "../../apis";
import { For, Match, Show, Switch, createEffect, splitProps } from "solid-js";
import { ModuleInfo } from "./side-info";
import { ROUTES } from "../../const";
import { getMaterialRoute, getMaterialType, getModuleRoute, getStageRoute } from "../../methods";
import { ModuleDetails, ModulesList, StageListDetails } from "../../models";
import { MaterialForm } from "./material-form";

export function EditSubModule(
  props: Ctrl.Routes.model.AdminViewProps & {
    module: AStageModule;
    submoduleID: string | number;
  }
) {
  const [local, data] = splitProps(props, ["$", "submoduleID"]);
  const $details = state.createAsync([getMaterialList, local.submoduleID]);
  local.$.actions.setLayout({
    title: "Edit Sub Module ",
    sideInfo: [
      // {
      //   title: data.module.name + " info",
      //   element: () => <ModuleInfo {...data.module} />,
      // },
    ],
  });
  // $details.on((event, { value }) => {
  //   console.log("submodule details set with :: ", value);
  // });
  // createEffect(() => {
  //   console.log("called rooow :: ", $details.state.success);
  // });

  function onCreate(values: ASubmoduleMaterial) {
    // if (!values.objectives) values.objectives = null;
    createMaterial(local.submoduleID, values)
      .then((obj) => {
        // console.log("obj is :: ", obj);
        $details.set((s) => {
          return { ...s, data: [...s.data, { ...values, id: obj.id }] };
        });
        local.$.actions.pushToast({
          type: "success",
          message: "Material Created",
        });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        local.$.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onUpdate(values: ASubmoduleMaterial, index: number) {
    updateMaterial(values.id, values)
      .then(() => {
        $details.set((s) => {
          const new_arr = [...s.data];
          new_arr[index] = { ...new_arr[index], ...values };
          console.log("update ::: ", new_arr);
          return { ...s, data: new_arr };
        });
        local.$.actions.pushToast({
          type: "success",
          message: "Material Update",
        });
      })
      .catch((res) => {
        console.log("res is :: ", res);
        const err = res.response.data.error;
        local.$.actions.pushToast({
          type: "error",
          message: typeof err === "string" ? err : err.join("\n"),
        });
      });
  }

  function onDelete(indices: number[]) {
    const ids = indices.map((i) => $details.value.data[i].id);
    const remove = [] as number[];
    // console.log("ids to delete are :: ", ids);
    // return;
    deleteMaterial(ids).then((obj) => {
      obj.forEach((item) => {
        if (item.error) {
          local.$.actions.pushToast({
            type: "error",
            message: item.error,
          });
        }
        remove.push(item.id);
      });
      // console.log("removed modules ", remove);
      if (remove.length > 0) {
        $details.set((s) => ({ ...s, data: s.data.filter((x, i) => !remove.includes(x.id)) }));
        // const new_arr = data.module.submodule.filter((x, i) => !remove.includes(x.id));
        // local.onUpdate(new_arr as any);
        local.$.actions.pushToast({
          type: "success",
          message: "Deleted " + remove.length + " modules",
        });
      }
    });
  }

  async function onClickedItem(item: ASubmoduleMaterial) {
    console.log("onClickedItem :: ", item, " :: type :: ", getMaterialType(item));
    if (getMaterialType(item) !== "lesson") {
      local.$.actions.pushToast({
        type: "error",
        message: "Only lessons can be edited at the moment!",
      });
      return;
    }
    let route = getMaterialRoute(item.id);
    // console.log("next route :: ", route);
    if (route) {
      local.$.actions.navigate(route);
    }
  }

  return (
    <main class="flex flex-col w-full h-full">
      <div class="flex flex-row w-full h-fit">
        <p class="">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        </p>
        {/* <button
          onclick={() => {
            $details.refetch();
          }}
        >
          refetch
        </button> */}
      </div>
      <Switch fallback={<div>unknown promise state </div>}>
        <Match when={$details.state.loading}>Loading...</Match>
        <Match when={$details.state.failed}>An error occured while fetching stage data {$details.error} ...</Match>
        <Match when={$details.state.success}>
          <Cmp.List.Table
            rows={$details.value.data}
            rowClass="w-full flex flex-row  text-20px"
            rowContainerClass="w-full flex flex-row rounded-lg px2 text-20px"
            colsClasses={{
              id: "!w-10% text-start capitalize",
              name: "!w-80% text-start capitalize ",
              order: "!w-10% text-start capitalize ",
              // actions: "!w-30% capitalize",
            }}
            events={{
              onAllRows({ event, selected }) {
                console.log("action for all is ", event, selected);
                if (event === "delete") {
                  onDelete(selected);
                } else if (event === "create") {
                  local.$.actions.pushModal({
                    title: "Create New Material Lesson",
                    element: (event) => (
                      <MaterialForm
                        materialID={undefined}
                        onDone={(values) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                }
              },
              onSingleRow({ event, item, index }) {
                if (event === "delete") {
                  onDelete([index]);
                } else if (event === "clicked") {
                  onClickedItem(item);
                } else if (event === "create") {
                  local.$.actions.pushModal({
                    title: `Create New Material Lesson (Template of ${item.name})`,
                    element: (event) => (
                      <MaterialForm
                        materialID={item.id}
                        onDone={(values) => {
                          event("close");
                          onCreate(values as any);
                        }}
                      />
                    ),
                  });
                } else if (event === "edit") {
                  local.$.actions.pushModal({
                    title: `Update Material ${item.name}`,
                    element: (event) => (
                      <MaterialForm
                        materialID={item.id}
                        buttonMsg="Update"
                        onDone={(values) => {
                          event("close");
                          onUpdate(values as any, index);
                        }}
                      />
                    ),
                  });
                }
              },
            }}
          />
        </Match>
      </Switch>
    </main>
  );
}
